// import logo from './logo.svg';
import React, { Suspense } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
// import Home from './Component/Home';
// import About from './Component/About';
// import Dashboard from './Component/user/Dashboard';
// import Faq from './Component/Faq/Faq';
// import Contact from './Component/Contact/Contact';
const Home = React.lazy(() => import('./Component/Home'));
const About = React.lazy(() => import('./Component/About'));
const Dashboard = React.lazy(() => import('./Component/user/Dashboard'));
const Faq = React.lazy(() => import('./Component/Faq/Faq'));
const Contact = React.lazy(() => import('./Component/Contact/Contact'));

function App() {
  return (
    <div className="App">
      <Suspense fallback={null}>
        <Routes>
          <Route excat path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<Faq />} />
          <Route path="contact" element={<Contact />} />
          <Route path="dashboard" element={<Dashboard />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
